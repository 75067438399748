<template>
  <video-background
      :src="require('../assets/animation/animation_two.mp4')"
      :loop="false"
      :muted="true"
      @playing="handlePlaying"
      @load="handleLoad"
      ref="videobackground"
      style="height: 100vh;">
    <div class="container h-100">
      <button style="display: none" ref="play" type="button" @click="onplay">Play</button>
      <p class="text-center text-white" style="margin-top: 30%">What is your favorite animal?</p>
      <div class="row justify-content-center">
        <div class="col-6">
          <input type="text" placeholder="" class="form-control form-input mt-2" @focus="handleFocus()"
                 autocomplete="nope" v-model="collect_data.favorite_animal" readonly="readonly"/>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button :disabled="loading" class="btn btn-secondary btn-style1" href="javascript:void(0)" @click="handleSubmit()">
          {{ loading ? "Submitting...": "Submit" }}</button>
      </div>
      <div class="d-flex justify-content-center">
        <a class="text-white mt-4" href="javascript:void(0)" @click="handleSubmit">Skip</a>
      </div>

      <search-animal :show="show" :toggle-search="toggleSearch" v-model="collect_data.favorite_animal"></search-animal>
    </div>
  </video-background>
</template>

<script>
import SearchAnimal from "@/components/SearchAnimal";
import {mapState} from "vuex";


export default {
  name: "FavoriteAnimal",
  components: {SearchAnimal},
  data() {
    return {
      show: false,
      loading: false
    }
  },
  computed: {
    ...mapState([
        'collect_data', 'fields'
    ]),
    enable_screen1() {
      return this.fields.show_name
    },
    enable_screen2() {
      return this.fields.show_phone && this.fields.show_email;
    },
    enable_screen3() {
      return this.fields.show_birthday && this.fields.show_country;
    },
    enable_screen4() {
      return this.fields.show_question;
    },
  },
  created() {
    if (localStorage.getItem("collectedData")) {
      this.$router.replace("/songs")
    }
    if (!this.enable_screen4) {
      this.$router.replace("/")
    }
    this.$store.dispatch("getAnimals");
  },
  methods: {
    onplay() {
      this.$refs.videobackground.player.play()
    },
    handleLoad() {
      this.$refs.play.touchstart();
    },
    handlePlaying() {
      let that = this;
      setTimeout(() => {
        const videoElement = document.querySelector('video');
        if (!videoElement.playing) {
          that.$refs.videobackground.poster = require('../assets/imgs/bg2.png');
          that.$refs.videobackground.player.hide()
        }
      }, 2000)
    },
    handleFocus() {
      this.show = Math.random().toString(36).substring(7);
    },
    toggleSearch(val) {
      this.show = val;
    },
    handleSubmit() {
      let that = this;
      that.loading = true
      this.$store.dispatch('addMember', this.collect_data);
      this.collect_data.save().then(() => {
        that.loading = false;
        localStorage.setItem("collectedData", "1");
        that.$router.replace("/songs")
      })
    },
    handleSelect(val) {
      this.collect_data.favorite_animal = val;
    }
  }
}
</script>

<style scoped>

</style>
